define('ember-intl/helpers/format-message', ['exports', 'ember', 'ember-intl/helpers/l', 'ember-intl/helpers/-format-base'], function (exports, _ember, _emberIntlHelpersL, _emberIntlHelpersFormatBase) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.getValue = getValue;
  var assert = _ember['default'].assert;

  function getValue(_ref, options) {
    var _ref2 = _slicedToArray(_ref, 1);

    var key = _ref2[0];

    if (key && key instanceof _emberIntlHelpersL.LiteralWrapper) {
      return key.value;
    }

    assert('[ember-intl] translation lookup attempted but no translation key was provided.', key);

    var fallback = options.fallback;
    var allowEmpty = options.allowEmpty;
    var defaultMessage = options.defaultMessage;
    var optionalLocale = options.locale;

    var fallbackTranslation = defaultMessage || fallback;

    var translation = this.intl.lookup(key, optionalLocale, {
      resilient: allowEmpty || typeof fallbackTranslation === 'string'
    });

    return translation || fallbackTranslation;
  }

  exports['default'] = _emberIntlHelpersFormatBase['default'].extend({
    getValue: getValue,

    format: function format(value, options) {
      return this.intl.formatMessage(value, options);
    }
  });
});
/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */