define('ember-intl/helpers/format-html-message', ['exports', 'ember-intl/helpers/-format-base', 'ember-intl/helpers/format-message'], function (exports, _emberIntlHelpersFormatBase, _emberIntlHelpersFormatMessage) {
  exports['default'] = _emberIntlHelpersFormatBase['default'].extend({
    getValue: _emberIntlHelpersFormatMessage.getValue,

    format: function format(value, options) {
      return this.intl.formatHtmlMessage(value, options);
    }
  });
});
/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */