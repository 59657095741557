define('ember-moment/helpers/-base', ['exports', 'ember'], function (exports, _ember) {
  var observer = _ember['default'].observer;
  var inject = _ember['default'].inject;
  var get = _ember['default'].get;
  var Helper = _ember['default'].Helper;
  var run = _ember['default'].run;
  exports['default'] = Helper.extend({
    moment: inject.service(),
    disableInterval: false,

    localeOrTimeZoneChanged: observer('moment.locale', 'moment.timeZone', function () {
      this.recompute();
    }),

    compute: function compute(value, _ref) {
      var interval = _ref.interval;

      if (get(this, 'disableInterval')) {
        return;
      }

      this.clearTimer();

      if (interval) {
        this.intervalTimer = run.later(this, 'recompute', parseInt(interval, 10));
      }
    },

    morphMoment: function morphMoment(time, _ref2) {
      var locale = _ref2.locale;
      var timeZone = _ref2.timeZone;

      var momentService = get(this, 'moment');

      locale = locale || get(momentService, 'locale');
      timeZone = timeZone || get(momentService, 'timeZone');

      if (locale && time.locale) {
        time = time.locale(locale);
      }

      if (timeZone && time.tz) {
        time = time.tz(timeZone);
      }

      return time;
    },

    clearTimer: function clearTimer() {
      run.cancel(this.intervalTimer);
    },

    destroy: function destroy() {
      this.clearTimer();
      this._super.apply(this, arguments);
    }
  });
});