define('ember-cli-nprogress/initializers/nprogress', ['exports', 'ember', 'ember-cli-nprogress'], function (exports, _ember, _emberCliNprogress) {
  exports.initialize = initialize;
  var run = _ember['default'].run;
  var scheduler = run.later.bind(undefined, undefined);

  exports.scheduler = scheduler;

  function initialize() {
    _emberCliNprogress['default'].configure({ scheduler: scheduler });
  }

  exports['default'] = {
    name: 'nprogress',
    initialize: initialize
  };
});