define('ember-intl/helpers/-format-base', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Helper = _ember['default'].Helper;
  var getOwner = _ember['default'].getOwner;
  var isEmpty = _ember['default'].isEmpty;
  var getWithDefault = _ember['default'].getWithDefault;

  var AbstractHelper = Helper.extend({
    intl: null,

    init: function init() {
      if (this.constructor === AbstractHelper) {
        throw new Error('FormatHelper is an abstract class, can not be instantiated directly.');
      }

      this._super.apply(this, arguments);

      this.intl = getOwner(this).lookup('service:intl');
      this.intl.on('localeChanged', this, this.recompute);
    },

    getValue: function getValue(_ref) {
      var _ref2 = _slicedToArray(_ref, 1);

      var value = _ref2[0];

      return value;
    },

    format: function format() {
      throw new Error('not implemented');
    },

    compute: function compute(params, options) {
      var value = this.getValue(params, options);
      var allowEmpty = getWithDefault(options, 'allowEmpty', this.allowEmpty);

      if (isEmpty(value)) {
        if ('fallback' in options) {
          return options.fallback;
        }

        if (allowEmpty) {
          return;
        }

        if (typeof value === 'undefined') {
          throw new Error(this + ' helper requires value attribute.');
        }
      }

      return this.format(value, options);
    },

    destroy: function destroy() {
      this._super.apply(this, arguments);

      this.intl.off('localeChanged', this, this.recompute);
    }
  });

  exports['default'] = AbstractHelper;
});
/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */