define('ember-place-autocomplete/components/place-autocomplete-field', ['exports', 'ember-place-autocomplete/templates/components/place-autocomplete-field'], function (exports, _placeAutocompleteField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      isEmpty = Ember.isEmpty,
      isPresent = Ember.isPresent,
      typeOf = Ember.typeOf,
      isEqual = Ember.isEqual,
      run = Ember.run;
  exports.default = Component.extend({
    layout: _placeAutocompleteField.default,
    disabled: false,
    inputClass: 'place-autocomplete--input',
    types: 'geocode',
    restrictions: {},
    tabindex: 0,
    withGeoLocate: false,
    setValueWithProperty: 'formatted_address',

    // @see https://developers.google.com/maps/documentation/javascript/places-autocomplete#set_search_area
    geolocate: function geolocate() {
      var _this = this;

      var navigator = this.get('navigator') || (window ? window.navigator : null);
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          var google = _this.get('google') || window.google;
          var geolocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          var circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          });
          _this.get('autocomplete').setBounds(circle.getBounds());
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      run.scheduleOnce('afterRender', this, 'setupComponent');
    },
    setupComponent: function setupComponent() {
      var _this2 = this;

      this.getAutocomplete();
      this.get('autocomplete').addListener('place_changed', function () {
        run(function () {
          _this2.placeChanged();
        });
      });
      if (this.get("withGeoLocate")) {
        this.geolocate();
      }
    },
    willDestroy: function willDestroy() {
      if (isPresent(this.get('autocomplete'))) {
        var google = this.get('google') || (window ? window.google : null);
        if (google.maps.event) {
          google.maps.event.clearInstanceListeners(this.get('autocomplete'));
        }
      }
    },
    getAutocomplete: function getAutocomplete() {
      if (isEmpty(this.get('autocomplete'))) {
        if (document && window) {
          var inputElement = document.getElementById(this.elementId).getElementsByTagName('input')[0],
              google = this.get('google') || window.google,
              //TODO: check how to use the inyected google object
          options = { types: this._typesToArray() };
          if (Object.keys(this.get('restrictions')).length > 0) {
            options.componentRestrictions = this.get('restrictions');
          }
          var autocomplete = new google.maps.places.Autocomplete(inputElement, options);
          this.set('autocomplete', autocomplete);
        }
      }
    },
    placeChanged: function placeChanged() {
      var place = this.get('autocomplete').getPlace();
      this._callCallback('placeChangedCallback', place);

      if (place[this.get('setValueWithProperty')] !== undefined) {
        this.set('value', place[this.get('setValueWithProperty')]);
      } else {
        // Address not found use value
        this.set('value', place.name);
      }
    },
    _callCallback: function _callCallback(callback, place) {
      var callbackFn = this.get(callback);
      if (isEqual(typeOf(callbackFn), 'function')) {
        callbackFn(place);
      } else {
        var actionName = this.get(callback);
        if (isPresent(this.get('handlerController')) && isPresent(actionName)) {
          this.get('handlerController').send(actionName, place);
        }
      }
    },
    _typesToArray: function _typesToArray() {
      if (this.get('types') !== "") {
        return this.get('types').split(',');
      } else {
        return [];
      }
    },


    actions: {
      focusOut: function focusOut() {
        this._callCallback('focusOutCallback');
      }
    }
  });
});