define('ember-intl/services/intl', ['exports', 'ember', 'intl-messageformat', 'intl-relativeformat', 'ember-intl/utils/links', 'ember-intl/utils/is-equal', 'ember-intl/utils/normalize-locale'], function (exports, _ember, _intlMessageformat, _intlRelativeformat, _emberIntlUtilsLinks, _emberIntlUtilsIsEqual, _emberIntlUtilsNormalizeLocale) {
  var assert = _ember['default'].assert;
  var getOwner = _ember['default'].getOwner;
  var computed = _ember['default'].computed;
  var makeArray = _ember['default'].makeArray;
  var _get = _ember['default'].get;
  var set = _ember['default'].set;
  var RSVP = _ember['default'].RSVP;
  var Service = _ember['default'].Service;
  var Evented = _ember['default'].Evented;
  var deprecate = _ember['default'].deprecate;

  var assign = _ember['default'].assign || _ember['default'].merge;

  function formatterProxy(formatType) {
    return function (value, options, formats) {
      if (!options) {
        if (arguments.length > 1) {
          _ember['default'].warn('[ember-intl] expected object for formatter ' + formatType + ' but received ' + typeof options, false, {
            id: 'ember-intl-missing-formatter-args'
          });
        }

        options = {};
      }

      if (typeof options.format === 'string') {
        options = assign(assign({}, this.getFormat(formatType, options.format)), options);
      }

      var formatter = this.owner.lookup('ember-intl@formatter:format-' + formatType);

      return formatter.format(value, options, {
        formats: formats || _get(this, 'formats'),
        locale: this._localeWithDefault(options.locale)
      });
    };
  }

  var IntlService = Service.extend(Evented, {
    _locale: null,

    locale: computed('_locale', {
      set: function set() {
        throw new Error('Use `setLocale` to change the application locale');
      },
      get: function get() {
        return _get(this, '_locale');
      }
    }),

    adapter: computed({
      get: function get() {
        return this.owner.lookup('ember-intl@adapter:default');
      }
    }),

    formats: computed({
      get: function get() {
        return this.owner.resolveRegistration('formats:main');
      }
    }),

    formatHtmlMessage: formatterProxy('html-message'),
    formatRelative: formatterProxy('relative'),
    formatMessage: formatterProxy('message'),
    formatNumber: formatterProxy('number'),
    formatTime: formatterProxy('time'),
    formatDate: formatterProxy('date'),
    requirejs: requirejs,

    init: function init() {
      this._super.apply(this, arguments);

      this.owner = getOwner(this);

      if (typeof Intl === 'undefined') {
        _ember['default'].warn('[ember-intl] Intl API is unavailable in this environment.\nSee: ' + _emberIntlUtilsLinks['default'].polyfill, false, {
          id: 'ember-intl-undefined-intljs'
        });
      }

      this._hydrate();
    },

    /**
     * Returns an array of registered locale names
     *
     * @property locales
     * @public
     */
    locales: computed.readOnly('adapter.locales'),

    /**
     * Peeks into the requirejs map and registers all locale data objects found.
     * This is also very likely to be removed soon.
     *
     * @private
     */
    _hydrate: function _hydrate() {
      var _this = this;

      var config = this.owner.resolveRegistration('config:environment');
      var cldrs = this._lookupByFactoryType('cldrs', config.modulePrefix);
      var translations = this._lookupByFactoryType('translations', config.modulePrefix);

      if (!cldrs.length) {
        _ember['default'].warn('[ember-intl] project is missing CLDR data\nIf you are asynchronously loading translation, see: ' + _emberIntlUtilsLinks['default'].asyncTranslations + '.', false, {
          id: 'ember-intl-missing-cldr-data'
        });
      }

      cldrs.map(function (moduleName) {
        return _this.owner.resolveRegistration('cldr:' + moduleName.split('\/').pop());
      }).forEach(function (data) {
        return data.forEach(_this.addLocaleData);
      });

      translations.forEach(function (moduleName) {
        var localeName = moduleName.split('\/').pop();

        _this.addTranslations(localeName, _this.owner.resolveRegistration('translation:' + localeName));
      });
    },

    _lookupByFactoryType: function _lookupByFactoryType(type, modulePrefix) {
      return Object.keys(this.requirejs._eak_seen).filter(function (key) {
        return key.indexOf(modulePrefix + '/' + type + '/') === 0;
      });
    },

    _localeWithDefault: function _localeWithDefault(localeName) {
      if (!localeName) {
        return _get(this, '_locale');
      }

      if (typeof localeName === 'string') {
        return makeArray(localeName).map(_emberIntlUtilsNormalizeLocale['default']);
      }

      if (Array.isArray(localeName)) {
        return localeName.map(_emberIntlUtilsNormalizeLocale['default']);
      }
    },

    lookup: function lookup(key, localeName) {
      var options = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var localeNames = this._localeWithDefault(localeName);
      var translation = _get(this, 'adapter').lookup(localeNames, key);

      if (!options.resilient && !translation) {
        var missingMessage = this.owner.resolveRegistration('util:intl/missing-message');

        return missingMessage.call(this, key, localeNames);
      }

      return translation;
    },

    t: function t(key) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      var options = args[0];

      var translation = this.lookup(key, options && options.locale);

      return this.formatMessage.apply(this, [translation].concat(args));
    },

    exists: function exists(key, localeName) {
      var localeNames = this._localeWithDefault(localeName);
      var adapter = _get(this, 'adapter');

      assert('[ember-intl] locale is unset, cannot lookup \'' + key + '\'', Array.isArray(localeNames) && localeNames.length);

      return localeNames.some(function (localeName) {
        return adapter.has(localeName, key);
      });
    },

    getLocalesByTranslations: function getLocalesByTranslations() {
      deprecate('[ember-intl] `getLocalesByTranslations` is deprecated, use `locales` computed property', false, {
        id: 'ember-intl-locales-cp'
      });

      return _get(this, 'locales');
    },

    /**
    * A utility method for registering CLDR data for
    * intl-messageformat and intl-relativeformat.  This data is derived
    * from formatjs-extract-cldr-data
    *
    * @method addLocaleData
    * @param {Object} locale data
    * @public
    */
    addLocaleData: function addLocaleData(data) {
      _intlMessageformat['default'].__addLocaleData(data);
      _intlRelativeformat['default'].__addLocaleData(data);
    },

    addTranslation: function addTranslation(localeName, key, value) {
      return this.localeFactory(localeName).then(function (locale) {
        return locale.addTranslation(key, value);
      });
    },

    addTranslations: function addTranslations(localeName, payload) {
      return this.localeFactory(localeName).then(function (locale) {
        return locale.addTranslations(payload);
      });
    },

    setLocale: function setLocale(localeName) {
      if (!localeName) {
        return;
      }

      var proposed = makeArray(localeName).map(_emberIntlUtilsNormalizeLocale['default']);
      var current = _get(this, '_locale');

      if (!(0, _emberIntlUtilsIsEqual['default'])(proposed, current)) {
        this.propertyWillChange('locale');
        set(this, '_locale', proposed);
        this.propertyDidChange('locale');
        this.trigger('localeChanged');
      }
    },

    getFormat: function getFormat(formatType, format) {
      var formats = _get(this, 'formats');

      if (formats && formatType && typeof format === 'string') {
        return _get(formats, formatType + '.' + format);
      }

      return {};
    },

    localeFactory: function localeFactory(localeName) {
      return RSVP.cast(_get(this, 'adapter').localeFactory((0, _emberIntlUtilsNormalizeLocale['default'])(localeName), true));
    },

    createLocale: function createLocale(localeName, payload) {
      deprecate('[ember-intl] `createLocale` is deprecated, use `addTranslations`', false, {
        id: 'ember-intl-create-locale'
      });

      return this.addTranslations(localeName, payload);
    },

    findTranslationByKey: function findTranslationByKey() {
      return this.lookup.apply(this, arguments);
    },

    translationsFor: function translationsFor() {
      return this.localeFactory.apply(this, arguments);
    }
  });

  exports['default'] = IntlService;
});
/* globals requirejs, Intl */

/**
 * Copyright 2015, Yahoo! Inc.
 * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
 */