define('ember-cli-segment/services/segment', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    init: function init() {
      this._super();

      var isFastBoot = typeof FastBoot !== 'undefined';

      if (!this.hasAnalytics() && this.config && this.config.environment !== 'test' && !isFastBoot) {
        _ember['default'].Logger.warn('Segment.io is not loaded yet (window.analytics)');
      }
    },

    hasAnalytics: function hasAnalytics() {
      return !!(window.analytics && typeof window.analytics === "object");
    },

    // Default true unless user explicitly sets defaultPageTrack to false
    pageTrackEnabled: function pageTrackEnabled() {
      return !this.pageTrackDisabled();
    },

    pageTrackDisabled: function pageTrackDisabled() {
      var hasSegmentConfig = this.config && this.config.segment;
      return hasSegmentConfig && this.config.segment.defaultPageTrack === false;
    },

    // Default true unless user explicitly sets defaultIdentifyUser to false
    identifyUserEnabled: function identifyUserEnabled() {
      return !this.identifyUserDisabled();
    },

    identifyUserDisabled: function identifyUserDisabled() {
      var hasSegmentConfig = this.config && this.config.segment;
      return hasSegmentConfig && this.config.segment.defaultIdentifyUser === false;
    },

    log: function log() {
      if (this.config && this.config.segment && this.config.segment.LOG_EVENT_TRACKING) {
        _ember['default'].Logger.info('[Segment.io] ', arguments);
      }
    },

    trackPageView: function trackPageView() {
      if (this.hasAnalytics()) {
        window.analytics.page.apply(this, arguments);

        this.log('trackPageView', arguments);
      }
    },

    trackEvent: function trackEvent(event, properties, options, callback) {
      if (this.hasAnalytics()) {
        window.analytics.track(event, properties, options, callback);
        this.log(event, properties, options);
      }
    },

    identifyUser: function identifyUser(userId, traits, options, callback) {
      if (this.hasAnalytics()) {
        window.analytics.identify(userId, traits, options, callback);
        this.log('identifyUser', traits, options);
      }
    },

    // reset group, user traits and id's
    reset: function reset() {
      if (this.hasAnalytics()) {
        window.analytics.reset();
        this.log("reset");
      }
    },

    group: function group(groupId, traits, options, callback) {
      if (this.hasAnalytics()) {
        window.analytics.group(groupId, traits, options, callback);
        this.log('group', traits, options);
      }
    },

    aliasUser: function aliasUser(userId, previousId, options, callback) {
      if (this.hasAnalytics()) {
        window.analytics.alias(userId, previousId, options, callback);
        this.log('aliasUser', previousId, options);
      }
    }
  });
});
/* globals FastBoot */