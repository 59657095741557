define('ember-plupload/system/upload-queue', ['exports', 'ember', 'ember-plupload/system/file', 'ember-plupload/system/trim', 'ember-plupload/system/sum-by'], function (exports, _ember, _emberPluploadSystemFile, _emberPluploadSystemTrim, _emberPluploadSystemSumBy) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var copy = _ember['default'].copy;
  var merge = _ember['default'].merge;

  var computed = _ember['default'].computed;
  var bool = _ember['default'].computed.bool;
  var bind = _ember['default'].run.bind;
  var keys = Object.keys;

  var getHeader = function getHeader(headers, header) {
    var headerKeys = _ember['default'].A(keys(headers));
    var headerIdx = headerKeys.map(function (s) {
      return s.toLowerCase();
    }).indexOf(header.toLowerCase());
    if (headerIdx !== -1) {
      return headers[headerKeys[headerIdx]];
    }
    return null;
  };

  /**
  
    @namespace ember-plupload
    @class UploadQueue
    @extend Ember.ArrayProxy
   */
  exports['default'] = _ember['default'].ArrayProxy.extend({

    name: null,

    uploading: bool('length'),

    queues: null,

    init: function init() {
      set(this, 'queues', _ember['default'].A([]));
      set(this, 'orphanedQueues', _ember['default'].A([]));

      set(this, 'content', _ember['default'].A([]));
      this._super();
    },

    configure: function configure() {
      var config = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      if (config.browse_button) {
        _ember['default'].assert('An element with the id "' + config.browse_button + '" is needed to match the uploader\'s for attribute.', document.getElementById(config.browse_button));
      }

      var uploader = new plupload.Uploader(config);

      uploader.bind('Init', bind(this, 'runtimeDidChange'));
      uploader.bind('FilesAdded', bind(this, 'filesAdded'));
      uploader.bind('FilesRemoved', bind(this, 'filesRemoved'));
      uploader.bind('BeforeUpload', bind(this, 'configureUpload'));
      uploader.bind('UploadProgress', bind(this, 'progressDidChange'));
      uploader.bind('FileUploaded', bind(this, 'fileUploaded'));
      uploader.bind('UploadComplete', bind(this, 'uploadComplete'));
      uploader.bind('Error', bind(this, 'onError'));

      get(this, 'queues').pushObject(uploader);

      // Set browse_button and drop_element as
      // references to the buttons so mOxie doesn't
      // get confused when the dom might be detached
      uploader.settings.browse_button = [config.browse_button];
      if (config.drop_element) {
        uploader.settings.drop_element = [config.drop_element];
      }

      var settings = copy(uploader.settings);
      delete settings.url;
      set(this, 'settings', settings);

      uploader.init();
      return uploader;
    },

    runtimeDidChange: function runtimeDidChange() {
      var $input = get(this, 'target').$('.moxie-shim input');
      var ruid = $input.attr('id');
      var I = mOxie.Runtime.getInfo(ruid);

      // Polyfill mobile support
      if (!I.can('summon_file_dialog')) {
        $input.attr('capture', 'camera');
      }
    },

    /**
      Orphan the active plupload object so
      we garbage collect the queues.
     */
    orphan: function orphan() {
      var orphans = get(this, 'orphanedQueues');
      var activeQueues = get(this, 'queues').filter(function (queue) {
        return orphans.indexOf(queue) === -1;
      });
      var freshestQueue = get(_ember['default'].A(activeQueues), 'lastObject');
      if (get(freshestQueue, 'total.queued') > 0) {
        orphans.pushObject(freshestQueue);
      } else {
        this.garbageCollectUploader(freshestQueue);
      }
    },

    destroy: function destroy() {
      this._super();
      get(this, 'queues').invoke('unbindAll');
      set(this, 'content', _ember['default'].A([]));
      set(this, 'queues', null);
    },

    refresh: function refresh() {
      get(this, 'queues').invoke('refresh');
    },

    size: computed({
      get: function _get() {
        return (0, _emberPluploadSystemSumBy['default'])(get(this, 'queues'), 'total.size') || 0;
      }
    }),

    loaded: computed({
      get: function _get() {
        return (0, _emberPluploadSystemSumBy['default'])(get(this, 'queues'), 'total.loaded') || 0;
      }
    }),

    progress: computed('size', 'loaded', {
      get: function _get() {
        var percent = get(this, 'loaded') / get(this, 'size') || 0;
        return Math.floor(percent * 100);
      }
    }),

    filesAdded: function filesAdded(uploader, files) {
      for (var i = 0, len = files.length; i < len; i++) {
        var file = _emberPluploadSystemFile['default'].create({
          uploader: uploader,
          file: files[i],
          queue: this
        });

        this.pushObject(file);
        get(this, 'target').sendAction('onfileadd', file, {
          name: get(this, 'name'),
          uploader: uploader,
          queue: this
        });

        this.notifyPropertyChange('size');
        this.notifyPropertyChange('loaded');
      }
    },

    filesRemoved: function filesRemoved(uploader, files) {
      for (var i = 0, len = files.length; i < len; i++) {
        var file = this.findBy('id', files[i].id);
        if (file) {
          this.removeObject(file);
        }
      }

      this.notifyPropertyChange('size');
      this.notifyPropertyChange('loaded');
    },

    configureUpload: function configureUpload(uploader, file) {
      file = this.findBy('id', file.id);
      // Reset settings for merging
      uploader.settings = copy(get(this, 'settings'));
      merge(uploader.settings, file.settings);

      this.progressDidChange(uploader, file);
    },

    progressDidChange: function progressDidChange(uploader, file) {
      file = this.findBy('id', file.id);
      if (file) {
        file.notifyPropertyChange('progress');
      }

      this.notifyPropertyChange('size');
      this.notifyPropertyChange('loaded');
    },

    parseResponse: function parseResponse(response) {
      var body = (0, _emberPluploadSystemTrim['default'])(response.response);
      var rawHeaders = _ember['default'].A(response.responseHeaders.split(/\n|\r/)).without('');
      var headers = rawHeaders.reduce(function (E, header) {
        var parts = header.split(/^([0-9A-Za-z_-]*:)/);
        if (parts.length > 0) {
          E[parts[1].slice(0, -1)] = (0, _emberPluploadSystemTrim['default'])(parts[2]);
        }
        return E;
      }, {});

      var contentType = (getHeader(headers, 'Content-Type') || '').split(';');
      // Parse body according to the Content-Type received by the server
      if (contentType.indexOf('text/html') !== -1) {
        body = _ember['default'].$.parseHTML(body);
      } else if (contentType.indexOf('text/xml') !== -1) {
        body = _ember['default'].$.parseXML(body);
      } else if (contentType.indexOf('application/json') !== -1 || contentType.indexOf('text/javascript') !== -1 || contentType.indexOf('application/javascript') !== -1) {
        body = _ember['default'].$.parseJSON(body);
      }

      return {
        status: response.status,
        body: body,
        headers: headers
      };
    },

    fileUploaded: function fileUploaded(uploader, file, response) {
      var results = this.parseResponse(response);
      file = this.findBy('id', file.id);
      if (file) {
        this.removeObject(file);
      }

      // NOTE: Plupload calls UploadProgress upon triggering FileUploaded,
      //       so we don't need to trigger a progress event
      if (Math.floor(results.status / 200) === 1) {
        file._deferred.resolve(results);
      } else {
        file._deferred.reject(results);
      }

      // Notify plupload that our browse_button may have
      // changed locations
      _ember['default'].run.later(uploader, 'refresh', 750);
    },

    garbageCollectUploader: function garbageCollectUploader(uploader) {
      get(this, 'queues').removeObject(uploader);
      get(this, 'orphanedQueues').removeObject(uploader);
      this.filterBy('uploader', uploader).invoke('destroy');
      uploader.unbindAll();
    },

    uploadComplete: function uploadComplete(uploader) {
      // Notify plupload that our browse_button may have
      // changed locations
      _ember['default'].run.later(uploader, 'refresh', 750);
      this.notifyPropertyChange('loaded');
      this.notifyPropertyChange('size');

      // Clean up the orphaned uploader and its files
      if (get(this, 'orphanedQueues').indexOf(uploader) !== -1) {
        this.garbageCollectUploader(uploader);
      }
    },

    onError: function onError(uploader, error) {
      if (error.file) {
        var file = this.findBy('id', error.file.id);
        if (file == null) {
          file = _emberPluploadSystemFile['default'].create({
            uploader: uploader,
            file: error.file
          });
        }

        set(file, 'error', error);

        if (file._deferred) {
          file._deferred.reject(error);

          // This happended before the file got queued,
          // So we need to stub out `upload` and trigger
          // the queued event
        } else {
            file.upload = file.read = function () {
              _ember['default'].run.debounce(uploader, 'refresh', 750);
              return _ember['default'].RSVP.reject(error, 'File: \'' + error.file.id + '\' ' + error.message);
            };
            file.isDestroyed = true;

            get(this, 'target').sendAction('onfileadd', file, {
              name: get(this, 'name'),
              uploader: uploader,
              queue: this
            });
          }
        this.notifyPropertyChange('length');
        _ember['default'].run.debounce(uploader, 'refresh', 750);
      } else {
        set(this, 'error', error);
        get(this, 'target').sendAction('onerror', error);
      }
    }
  });
});
/* globals plupload, mOxie */