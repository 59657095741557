define('ember-cli-pickadate/components/pick-a-time', ['exports', 'ember', 'ember-cli-pickadate/components/picker'], function (exports, _ember, _emberCliPickadateComponentsPicker) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var Component = _ember['default'].Component;
  var isEmpty = _ember['default'].isEmpty;
  var isNone = _ember['default'].isNone;
  var observer = _ember['default'].observer;

  var DEFAULT_TIME_FORMAT = 'hh-i';

  /**
   * @public
   *
   * @param disabled - (boolean) Disable the timepicker
   * @param placeholder - (string) The text to display in the input when nothing is selected
   * @param options - (object) Options available via the pick-a-date API
   * @param date - (Date) The inital date to display
   * @param on-selected - (function) Called when a time is selected and passed the new date as the first argument.
   * @param nulls-date - (boolean) If true, will set the date to null when the clear button is pressed.
   *                               If false, will set the time part to 0 only when the clear button is pressed, the date part is unaffected.
   */
  exports['default'] = _emberCliPickadateComponentsPicker['default'].extend({
    placeholder: "Select a time",
    classNames: ['ember-pick-a-time'],

    didInsertElement: function didInsertElement() {
      var _this = this;

      var defaults = this.getOptions().date;
      var options = _extends({}, defaults, this.attrs.options);

      for (var option in options.value) {
        options[option] = options.value[option];
      }

      options.onClose = options.onClose || this.onClose;
      options.onSet = function () {
        _this.onSelected();
      };
      this.$().pickatime(options);
      this.set('picker', this.$().pickatime('picker'));
    },

    updateInputText: function updateInputText() {
      var date = this.get('date');
      var options = this.attrs.options || {};
      var format = options.format || DEFAULT_TIME_FORMAT;
      var picker = this.get('picker');

      if (!picker.get('open')) {
        picker.set('select', date, {
          format: format,
          muted: true
        });
      }
    },

    onSelected: function onSelected() {
      var date = this.get('date') || new Date();
      var dateItem = this.get('picker').get('select');
      var newDate = new Date(date);
      if (isNone(dateItem)) {
        if (this.attrs['nulls-date'] === true) {
          newDate = null;
        } else {
          newDate.setHours(0, 0, 0, 0);
        }
      } else {
        newDate.setHours(dateItem.hour, dateItem.mins, 0, 0);
      }

      if (this.attrs['on-selected']) {
        this.attrs['on-selected'](newDate);
      }
    }
  });
});